import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Chip, Pagination, Stack, Rating, Button, ListItem, ListItemIcon, ListItemText, CircularProgress } from '@mui/material';
import Map from './Map';
import { AccessTimeOutlined, PhoneOutlined, LocationOnOutlined } from '@mui/icons-material';
import axios from 'axios';
import { useSearch } from '../context/SearchContext';
import useGeoLocation from '../context/useGeoLocation';
import { calculateDistance } from '../../utils/Calculations';
const Locations = () => {

    const [page, setPage] = useState(1);
    const [clinics, setClinics] = useState([]);
    const [originalClinics, setOriginalClinics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const location = useGeoLocation();
    const { searchQuery, filterCondition } = useSearch();
    let response;
    useEffect(() => {
        const fetchClinics = async () => {
            try {
                response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/clinics`);
                const clinicsWithNames = await Promise.all(response.data.map(async (clinic) => {
                    const [latitude, longitude] = clinic.Location.split(',');
                    if (latitude && longitude) {
                        const locationResponse = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
                        const locationName = `${locationResponse.data.display_name}`;
                        return {
                            ...clinic, Location: {
                                locationName: locationName,
                                coords: {
                                    lat: latitude,
                                    lng: longitude,
                                    clinicName: clinic.ClinicName,
                                    iconName: clinic.imageName
                                }
                            }
                        };
                    } else {
                        return {
                            ...clinic, Location: {
                                locationName: 'Unknown Location',
                                coords: {
                                    lat: -1,
                                    lng: -1,
                                    clinicName: clinic.ClinicName,
                                    iconName: clinic.imageName
                                }
                            }
                        }
                    }
                }));
                setClinics(clinicsWithNames);
                setLoading(false);
                setOriginalClinics(clinicsWithNames);
            } catch (error) {
		setClinics(response.data);
		setOriginalClinics(response.data);
                console.error('Error fetching clinics:', error);
                setLoading(false);
            }
        };
        fetchClinics();
    }, []);

    const updateBookNumber = async (clinic) => {
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/api/clinics/${clinic._id}`);
        } catch (err) {
            console.error(err);
        }
    }

    const applySelectedSearch = (clinic) => {
        return (
            clinic.ClinicName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
            clinic.tags.some(tag => tag.toLowerCase() === searchQuery.toLowerCase())
        );
    };

    const applyFilterCondition = (clinic) => {

        return (
            filterCondition === 'None' || (location &&
                calculateDistance(clinic.Location.coords.lat, clinic.Location.coords.lng, location.lat, location.lng) <= filterCondition)
        )
    }

    useEffect(() => {
        const filteredClinics = originalClinics.filter(
            (clinic) =>
                applySelectedSearch(
                    clinic) && applyFilterCondition(clinic));

        setClinics(filteredClinics);
    }, [originalClinics, searchQuery, filterCondition]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleLocationClick = (location) => {
        setSelectedLocation(location);
    };

    const itemsPerPage = 4;
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = clinics && clinics.slice(startIndex, endIndex);

    return loading ? (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
            <CircularProgress />
        </Grid>) : (
        <div style={{ padding: '2%', background: '#F8F8F7', marginTop: '2%' }}>
            <Grid container spacing={3} sx={{ pr: { xs: '2%', md: '7%' }, pl: { xs: '2%', md: '7%' } }}>
                <Grid item xs={12} md={6}>
                    {paginatedData && paginatedData.map((item) => (
                        <Card sx={{ mt: '2%' }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <img src={`${process.env.REACT_APP_BASE_URL}/api/clinic-images/${item.imageName}`} alt="Dental Clinic" width="100%" />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Stack direction="column" spacing={1}>
                                            <Typography component="div" sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: { xs: '18px', md: '20px' }, fontWeight: '700', color: '#034641' }}>
                                                {item.ClinicName}
                                            </Typography>
                                            <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
                                                {item.tags && item.tags.map((tag, index) => (
                                                    <Chip key={index} label={tag} variant="outlined" size="small" sx={{ background: '#DCF0FA', color: '#034641', fontFamily: 'Plus Jakarta Sans', fontWeight: '600', fontSize: { xs: '12px', md: '16px' } }} />
                                                ))}
                                            </Stack>

                                            <Rating name="read-only" value={item.Rating} readOnly precision={0.1} />
                                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: { xs: '12px', md: '14px' } }}>
                                                {item.Description}
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={12} md={6}>
                                                    <ListItem sx={{ pl: '0' }}>
                                                        <ListItemIcon sx={{ minWidth: 'unset', mr: '2%' }}>
                                                            <PhoneOutlined sx={{ color: '#F06E5A' }} />

                                                        </ListItemIcon>
                                                        <ListItemText primary={item.PhoneNumber} sx={{ fontSize: { xs: '10px', md: '14px' } }} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <ListItem sx={{ pl: '0' }}>
                                                        <ListItemIcon sx={{ minWidth: 'unset', mr: '2%' }}>

                                                            <AccessTimeOutlined sx={{ color: '#F06E5A' }} />

                                                        </ListItemIcon>
                                                        <ListItemText primary={item.WorkingHours} sx={{ fontSize: { xs: '10px', md: '14px' } }} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ListItem sx={{ pl: '0' }}>
                                                        <ListItemIcon sx={{ minWidth: 'unset', mr: '2%' }}>

                                                            <LocationOnOutlined sx={{ color: '#F06E5A' }} />

                                                        </ListItemIcon>
                                                        <ListItemText  sx={{ cursor: 'pointer', fontSize: { xs: '10px', md: '14px' } }} primary={item.Location.locationName} onClick={() => handleLocationClick(item.Location.coords)} />
                                                    </ListItem>
                                                </Grid>
                                            </Grid>


                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Button fullWidth variant="contained" sx={{
                                    mt: 2, background: '#F06E5A', color: '#fff', padding: '2% 3%', '&:hover': {
                                        background: '#F06E5A',
                                    }, textTransform: 'none'
                                }} href="https://www.dentinnova.com" target='_blank' onClick={() => { updateBookNumber(item) }}>
                                    Book an Appointment
                                </Button>
                            </CardContent>
                        </Card>
                    ))}
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{ marginTop: '2%' }}>
                        <Map selectedLocation={selectedLocation} location={location} />
                    </div>
                </Grid>
            </Grid>
            <div style={{ marginTop: '5%' }}  >
                <Pagination
                    sx={{
                        display: 'flex', justifyContent: 'center', color: 'black'

                    }}
                    count={Math.ceil(clinics.length / itemsPerPage)}
                    page={page}
                    shape='rounded'
                    variant='outlined'
                    onChange={handleChangePage}
                />
            </div>
        </div>
    );
};

export default Locations;
