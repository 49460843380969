import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, Typography, Paper, Box, LinearProgress, Avatar, CircularProgress } from '@mui/material';
import axios from 'axios';
import moment from 'moment-timezone';
import DownloadPdfButton from "./PDFButton";
import logo from '../static/logo-3.png';
import dentistImage from '../static/ellipse-104.png';
import EmptyPage from './404';
export const ReportPage = () => {
  const { id } = useParams();
  const [severity, serSeverity] = useState([]);
  const [diagnosesArray, setDiagnosesArray] = useState([]);
  const [treatmentsArray, setTreatmentsArray] = useState([]);
  const [productsArray, setProductsArray] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);
  const [dentistComment, setDentistComment] = useState('');
  const [problemDescription, setProblemDescription] = useState('');
  const [patientName, setPatientName] = useState('');
  const [patientEmail, setPatientEmail] = useState('');
  const [patientPhone, setPatientPhone] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [date, setDate] = useState('');
  const [loading, setLoading] = useState(true);
  const contentRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/reports/${id}`);
        const fetchedReport = response.data;

        // Set individual state variables
        setProblemDescription(fetchedReport.ProblemDescription);
        setDentistComment(fetchedReport.DentistComment);

        // Extract severity array
        const severityArray = fetchedReport.ImageDetails.map(imageDetail => {
          return imageDetail.Diseases.map(disease => disease.SeverityScale);
        });
        serSeverity(severityArray.flat());

        const fetchedCase = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/cases/${fetchedReport.PatientID}`);
        if (fetchedCase.data) {
          setPatientName(fetchedCase.data.user_name);
          setPatientEmail(fetchedCase.data.user_email);
          setPatientPhone(fetchedCase.data.user_phone_no);
          const dateObj = moment.tz(fetchedCase.data.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');
          const formattedDate = dateObj.format('DD MMM YYYY');
          setDate(formattedDate);
          setServiceType(fetchedCase.data.coverage_type);
        }

        // Extract diagnosis array
        const diagnosisArray = await Promise.all(fetchedReport.ImageDetails.map(async imageDetail => {
          const diseaseId = imageDetail.Diseases[0].DiseaseID;
          if (diseaseId) {
            const diseaseResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/diagnoses/${diseaseId}`);
            return {
              name: diseaseResponse.data.Name,
              description: diseaseResponse.data.Description,
              treatmentsNumber: imageDetail.Diseases[0].Treatments.length,
              productsNumber: imageDetail.Diseases[0].Products.length
            };
          }
        }));

        setDiagnosesArray(diagnosisArray);


        const imageArray = await Promise.all(fetchedReport.ImageDetails.map(async imageDetail => {
          if (imageDetail.ImageID) {
            const imageResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/images/${imageDetail.ImageID}`);
            return imageResponse.data;
          }
        }));
        setImagesArray(imageArray);


        // Extract treatment array
        const treatmentsArray = fetchedReport.ImageDetails.map(imageDetail => {
          return imageDetail.Diseases.map(disease => {
            return disease.Treatments.map(async treatmentId => {
              const treatmentResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/treatments/${treatmentId}`);
              return treatmentResponse.data;
            });
          });
        });
        setTreatmentsArray((await Promise.all(treatmentsArray.flat().flat())).flat());

        // Extract product array
        const productsArray = fetchedReport.ImageDetails.map(imageDetail => {
          return imageDetail.Diseases.map(disease => {
            return disease.Products.map(async productId => {
              const productResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/products/${productId}`);
              return productResponse.data;
            });
          });
        });
        setProductsArray((await Promise.all(productsArray.flat().flat())).flat());

      } catch (error) {
        console.error("Error fetching report:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);


  return loading ? (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <CircularProgress />
    </Grid>
  ) : diagnosesArray.length > 0 ? (
    <div ref={contentRef}>
      <Container maxWidth={false}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12} lg={8} justifyContent='center' >
            <Paper sx={{ pb: '5%', pt: 0, pr: '5%', pl: '5%', background: "#DCF0FA", position: "relative" }}>
              <Grid container spacing={3} alignItems={"center"}>
                <Grid item xs={12} md={4}>
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ height: "50px", width: '220px' }}
                  />
                </Grid>
                <Grid item xs={12} md={8} sx={{ textAlign: { xs: 'left', md: 'right' } }}>
                  <Typography
                    sx={{
                      mt: '1%',
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: { xs: '10px', md: "15px" },
                      fontStyle: "italic",
                      fontWeight: "700",
                      lineHeight: "140%",
                      opacity: "0.4",
                    }}

                  >
                    Your Online Dentist Anytime, Anywhere
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Typography textAlign="left">
                  <span
                    style={{
                      fontWeight: "500",
                      color: "#F06E5A",
                      fontFamily: "Satoshi",
                      fontSize: { xs: '30px', md: "40px" },
                    }}
                  >
                    Patient{" "}
                  </span>
                  <span
                    style={{
                      fontWeight: "900",
                      color: "#F06E5A",
                      fontFamily: "Satoshi",
                      fontSize: { xs: '30px', md: "40px" },
                    }}
                  >
                    Report
                  </span>
                </Typography>
              </Grid>
              <Typography
                sx={{
                  color: "#034641",
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: { xs: '15px', md: "25.212px" },
                  fontWeight: "800",
                  textAlign: "left",
                }}

              >
                Dental Care
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={8} justifyContent='center'>
            <Grid container spacing={3} marginTop={2}>
              <Grid item xs={6} md={4}>
                <Typography sx={{ color: '#f06e5a', fontFamily: 'Plus Jakarta Sans', fontWeight: '800', fontSize: { xs: '12px', md: '16px' } }} gutterBottom>
                  PATIENT INFO
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                  <span style={{ fontWeight: '700', color: '#f06e5a', fontFamily: 'Plus Jakarta Sans' }}>Name:</span> {patientName}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                  <span style={{ fontWeight: '700', color: '#f06e5a', fontFamily: 'Plus Jakarta Sans' }}>Email:</span> {patientEmail}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                  <span style={{ fontWeight: '700', color: '#f06e5a', fontFamily: 'Plus Jakarta Sans' }}>Phone:</span> {patientPhone}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography sx={{ color: '#f06e5a', fontFamily: 'Plus Jakarta Sans', fontWeight: '800', fontSize: { xs: '12px', md: '16px' } }} gutterBottom>
                  SERVICE
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                  <span style={{ fontWeight: '700', color: '#f06e5a', fontFamily: 'Plus Jakarta Sans' }}>Type:</span> {serviceType}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                  <span style={{ fontWeight: '700', color: '#f06e5a', fontFamily: 'Plus Jakarta Sans' }}>Date:</span> {date}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} >
                <DownloadPdfButton contentRef={contentRef} />
              </Grid>

            </Grid>


            <Paper sx={{ p: { xs: 2, md: 4 }, mt: 3, background: '#f06e5a40', borderRadius: '25px' }}>
              <Typography sx={{ color: '#f06e5a', fontFamily: 'Plus Jakarta Sans', fontWeight: '800', fontSize: { xs: '12px', md: '16px' }, letterSpacing: '0.2%', lineHeight: '140%' }} gutterBottom>
                PROBLEM DESCRIPTION
              </Typography>
              <Typography sx={{
                fontFamily: "Plus Jakarta Sans",
                fontSize: { xs: '16px', md: '24px' },
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '140%',
                letterSpacing: '0.1%'
              }}>
                {problemDescription}
              </Typography>
            </Paper>

            <Grid container spacing={3} marginTop={5} marginBottom={5} alignItems={"center"}>
              <Grid item xs={4} md={3}>
                <Avatar
                  src={dentistImage}
                  alt="Dentist"
                  sx={{ width: { xs: 100, md: 220 }, height: { xs: 100, md: 220 } }}
                />


              </Grid>
              <Grid item xs={8} md={9}>
                <Typography sx={{ color: '#034641', fontFamily: 'Plus Jakarta Sans', fontWeight: '800', fontSize: { xs: '12px', md: '16px' } }} gutterBottom>
                  DENTIST INFO
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '12px', md: '24px' } }}>
                  <span style={{ fontWeight: '700', color: '#034641', fontFamily: 'Plus Jakarta Sans' }}>Name:</span> <span style={{ fontWeight: '500', fontFamily: 'Plus Jakarta Sans' }}>Dr. {'Mariam AlZaabi'}</span>
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '12px', md: '24px' } }}>
                  <span style={{ fontWeight: '700', color: '#034641', fontFamily: 'Plus Jakarta Sans' }}>Title:</span> <span style={{ fontWeight: '500', fontFamily: 'Plus Jakarta Sans' }}>{'General Dentist'}</span>
                </Typography>
              </Grid>
            </Grid>
            <Paper sx={{ p: { xs: 2, md: 4 }, mt: 3, background: '#03464140', borderRadius: '25px' }}>
              <Typography sx={{ color: '#034641', fontFamily: 'Plus Jakarta Sans', fontWeight: 'bold', fontSize: { xs: '12px', md: '16px' }, letterSpacing: '0.8px', lineHeight: '140%' }} >
                Dentist Comment
              </Typography>
              <Typography sx={{
                fontFamily: "Plus Jakarta Sans",
                fontSize: { xs: '16px', md: '24px' },
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '140%',
                letterSpacing: '0.48px'
              }}>
                {dentistComment}
              </Typography>
            </Paper>


            {diagnosesArray && diagnosesArray.map((diagnosis, index) => diagnosis ? (
              <>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={4} md={7}>
                    <Typography sx={{
                      color: '#F06E5A',
                      fontFamily: 'Satoshi',
                      fontSize: { xs: '18px', md: '36px' },
                      fontWeight: '900',
                      lineHeight: '140%',
                      textAlign: 'left',
                    }} gutterBottom mt={8}>
                      Diagnosis
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={5} container alignItems={'center'}>
                    <Typography sx={{
                      color: '#034641',
                      fontFamily: 'Satoshi',
                      fontSize: { xs: '18px', md: '36px' },
                      fontWeight: '700',
                      lineHeight: '140%',
                      textAlign: 'right',
                    }} gutterBottom mt={8}>
                      Severity
                    </Typography>
                    <Box sx={{ width: '50%' }} mt={8} ml={2}>
                      <LinearProgress
                        variant="determinate"
                        value={severity[index] * 10}
                        sx={{
                          height: '20px',
                          borderRadius: '10px',
                          '& .MuiLinearProgress-bar': {
                            borderRadius: '10px',
                            backgroundColor: severity[index] <= 3 ? '#81F05A' : severity[index] <= 7 ? '#FFF500' : '#FF0000',
                          }
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Paper key={index} sx={{ p: { xs: 3, md: 5 }, mt: 2, borderRadius: '20px', background: '#f06e5a40' }}>
                  <Grid container spacing={3} alignItems={"center"}>

                    <Grid item xs={8} >
                      <Typography sx={{ fontFamily: 'Satoshi', color: '#034641', fontSize: { xs: '18px', md: '36px' }, fontWeight: '700' }} gutterBottom>
                        {diagnosis.name}
                      </Typography>
                      <Typography sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: { xs: '12px', md: '24px' },
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '140%',
                      }}>
                        {diagnosis.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Avatar
                        alt="Dentist"
                        src={`data:image/jpeg;base64,${imagesArray[index].image_base}`}
                        sx={{ width: { xs: 90, md: 220 }, height: { xs: 90, md: 220 } }}
                      />
                    </Grid>
                  </Grid>
                  <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: '800', fontSize: { xs: '12px', md: '16px' }, mt: 5, color: '#034641' }} gutterBottom>
                    TREATMENT
                  </Typography>
                  {
                    [...Array(diagnosis.treatmentsNumber)].map((_, i) => {
                      const startIndex = diagnosesArray
                        .slice(0, index)
                        .reduce((totalTreatments, currentDiagnosis) => totalTreatments + currentDiagnosis.treatmentsNumber, 0);
                      const treatmentIndex = startIndex + i;
                      return (
                        <div key={i}>
                          <Typography variant="body1" gutterBottom mt={5} sx={{ fontSize: { xs: '16px', md: '36px' } }}>
                            <span style={{ fontWeight: '700', fontFamily: 'Satoshi' }}>{treatmentsArray[treatmentIndex].Name}</span>
                            <span style={{ color: '#f06e5a', fontWeight: '900', fontFamily: 'Satoshi' }}> {treatmentsArray[treatmentIndex].Cost?` Cost ${treatmentsArray[treatmentIndex].Cost} AED`:''}</span>
                          </Typography>
                          <Typography sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: { xs: '12px', md: '24px' },
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '140%',
                          }}>
                            {treatmentsArray[treatmentIndex].Description}
                          </Typography>
                        </div>
                      );
                    })
                  }

                  <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: '800', fontSize: { xs: '12px', md: '16px' }, mt: 5, color: '#034641' }}>
                    RECOMMENDED PRODUCTS
                  </Typography>
                  {
                    [...Array(diagnosis.productsNumber)].map((_, i) => {
                      const startIndex = diagnosesArray
                        .slice(0, index)
                        .reduce((totalProducts, currentDiagnosis) => totalProducts + currentDiagnosis.productsNumber, 0);
                      const productIndex = startIndex + i;

                      return (
                        <div key={i}>
                          <Typography sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: { xs: '12px', md: '24px' },
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '140%',
                            mt: '2%'
                          }}>
                            - {productsArray[productIndex].name}
                          </Typography>
                        </div>
                      );
                    })
                  }



                </Paper>
              </>
            ) : (<></>))}
            <Typography variant="body1" align="center" mt={10} sx={{
              color: '#00000080', textAlign: 'center',
              fontFamily: 'Inter',
              fontSize: { xs: '8px', md: '14px' },
              fontWeight: '400',
              lineHeight: '150%',
            }}>
              Please be aware that while our teledental services provide expert guidance, they do not replace in-office dental care.
              By engaging with our services, you agree to our Privacy Policy and Terms of Service, acknowledging the scope and limitations
              of teledental consultations
            </Typography>

            <Typography variant="body2" align="center" sx={{ mt: 3, color: '#00000080', fontSize: { xs: '8px', md: '14px' }, }}>
              <hr />
              © - Copyright - 2023 - Dentinnova, Inc. - All rights reserved
            </Typography>
          </Grid>

        </Grid>
      </Container >
    </div >

  ) : (< EmptyPage />);
};
