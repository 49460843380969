import { useEffect, useState } from "react";

const useGeoLocation = () => {

    const [error, setError] = useState(null);
    const [location, setLocation] = useState(
        {
            lat: '',
            lng: ''
        }
    );
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
                    setError(null);
                },
                (err) => {
                    setError(err.message);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                }
            );
        } else {
            setError('Geolocation is not supported by your browser.');
        }
    }, []);


    return location;
}

export default useGeoLocation;