import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Box, Drawer, List, ListItemButton, ListItemText, IconButton, Typography, TextField, InputAdornment, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import logo from '../../static/logo2.png';
import { useSearch } from '../context/SearchContext';

const Header = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const { updateSearchQuery, updateFilterCondition } = useSearch();

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    return (
        <>
            <AppBar position="static" sx={{ background: '#fff', pr: '5%', pl: '5%', display: { xs: 'none', md: 'block' } }}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <img src={logo} alt="Logo" style={{ height: 40 }} />
                    </Box>

                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={openDrawer}
                onClose={toggleDrawer}
                sx={{ display: { xs: 'block', md: 'none' } }}
            >
                <Box sx={{ width: 250 }} p={1}>
                    {/* Logo */}
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                        <img src={logo} alt="Logo" style={{ height: 40 }} />
                    </Box>
                    <List>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemText primary="About" />
                        </ListItemButton>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemText primary="Services" />
                        </ListItemButton>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemText primary="How It Works" />
                        </ListItemButton>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemText primary="Benefits" />
                        </ListItemButton>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemText primary="Dental Directory" />
                        </ListItemButton>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemText primary="Get Started" />
                        </ListItemButton>
                    </List>
                    <TextField
                        placeholder="Search by name or tag"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        sx={{ background: '#fff', borderRadius: '10px', border: '3px solid #81C1E1' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        onClick={
                                            () => {

                                                updateSearchQuery(searchValue);

                                            }
                                        }
                                        sx={{
                                            background: '#F06E5A', fontSize: { xs: '10px', md: '16px' }, color: '#fff', textTransform: 'none', '&:hover': {
                                                background: '#F06E5A'
                                            }, p: '4%'
                                        }}>Find a clinic</Button>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Drawer>
            <Grid container>
                <Grid item xs={2}>
                    <Box sx={{ display: { xs: 'block', md: 'none' }, position: 'absolute', top: 0, left: 0 }}>
                        <IconButton onClick={toggleDrawer} sx={{ ml: 2 }}>
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={3} alignContent={'center'} sx={{ display: { xs: 'block', md: 'none' }, mt: '2%' }}>

                    <Typography sx={{ fontSize: '9px', fontWeight: '700', fontFamily: 'Inter', color: '#000', mr: '2%', }}>Filter Distance By</Typography>
                </Grid>
                <Grid item xs={7} sx={{ display: { xs: 'block', md: 'none' }, mt: '2%' }}>

                    <Button onClick={() => { updateFilterCondition('None') }} sx={{ background: '#F5F5F5', color: '#464646', fontSize: '12px', fontWeight: 600, letterSpacing: '3%', fontFamily: 'Inter', mr: '1%', minWidth: 'fit-content' }}>None</Button>
                    <Button onClick={() => { updateFilterCondition(5) }} sx={{ background: '#F5F5F5', color: '#464646', fontSize: '12px', fontWeight: 600, letterSpacing: '3%', fontFamily: 'Inter', mr: '1%', minWidth: 'fit-content' }}>5 KM</Button>
                    <Button onClick={() => { updateFilterCondition(15) }} sx={{ background: '#F5F5F5', color: '#464646', fontSize: '12px', fontWeight: 600, letterSpacing: '3%', fontFamily: 'Inter', mr: '1%', minWidth: 'fit-content' }}>15 KM</Button>
                    <Button onClick={() => { updateFilterCondition(25) }} sx={{ background: '#F5F5F5', color: '#464646', fontSize: '12px', fontWeight: 600, letterSpacing: '3%', fontFamily: 'Inter', mr: '1%', minWidth: 'fit-content' }}>25 KM</Button>
                    <Button onClick={() => { updateFilterCondition(35) }} sx={{ background: '#F5F5F5', color: '#464646', fontSize: '12px', fontWeight: 600, letterSpacing: '3%', fontFamily: 'Inter', mr: '1%', minWidth: 'fit-content' }}>35 KM</Button>
                    <Button onClick={() => { updateFilterCondition(45) }} sx={{ background: '#F5F5F5', color: '#464646', fontSize: '12px', fontWeight: 600, letterSpacing: '3%', fontFamily: 'Inter', mr: '1%', minWidth: 'fit-content' }}>45 KM</Button>

                </Grid>
            </Grid>
            <AppBar position="static" sx={{ pr: '5%', pl: '5%', background: '#DCF0FA', border: '2px solid #000', pt: '1%', pb: '1%', display: { xs: 'none', md: 'block' } }}>
                <Toolbar>
                    {/* Logo on the left */}
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography sx={{ fontSize: '16px', fontFamily: 'Inter', color: '#000' }}>Search For</Typography>

                        <Typography sx={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Inter', color: '#000' }}>Nearby Dental Clinics</Typography>
                    </Box>

                    {/* Navigation options on the right */}
                    <TextField
                        placeholder="Search by name or tag"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        sx={{ background: '#fff', borderRadius: '10px', border: '3px solid #81C1E1' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        onClick={() => {
                                            updateSearchQuery(searchValue);
                                        }}
                                        sx={{
                                            background: '#F06E5A', color: '#fff', textTransform: 'none', '&:hover': {
                                                background: '#F06E5A'
                                            }
                                        }}>Find a clinic</Button>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                </Toolbar>
            </AppBar>

            <AppBar position="static" sx={{ pr: '5%', pl: '5%', background: '#fff', border: '3px solid #81C1E1', borderTop: 'none', display: { xs: 'none', md: 'block' } }}>
                <Toolbar>
                    {/* Logo on the left */}


                    <Typography sx={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Inter', color: '#000', mr: '2%' }}>Filter Distance By</Typography>



                    <Button onClick={() => { updateFilterCondition('None') }} sx={{ background: '#F5F5F5', color: '#464646', fontSize: '14px', fontWeight: 600, letterSpacing: '3%', fontFamily: 'Inter', mr: '1%' }}>None</Button>
                    <Button onClick={() => { updateFilterCondition(5) }} sx={{ background: '#F5F5F5', color: '#464646', fontSize: '14px', fontWeight: 600, letterSpacing: '3%', fontFamily: 'Inter', mr: '1%' }}>5 KM</Button>
                    <Button onClick={() => { updateFilterCondition(15) }} sx={{ background: '#F5F5F5', color: '#464646', fontSize: '14px', fontWeight: 600, letterSpacing: '3%', fontFamily: 'Inter', mr: '1%' }}>15 KM</Button>
                    <Button onClick={() => { updateFilterCondition(25) }} sx={{ background: '#F5F5F5', color: '#464646', fontSize: '14px', fontWeight: 600, letterSpacing: '3%', fontFamily: 'Inter', mr: '1%' }}>25 KM</Button>
                    <Button onClick={() => { updateFilterCondition(35) }} sx={{ background: '#F5F5F5', color: '#464646', fontSize: '14px', fontWeight: 600, letterSpacing: '3%', fontFamily: 'Inter', mr: '1%' }}>35 KM</Button>
                    <Button onClick={() => { updateFilterCondition(45) }} sx={{ background: '#F5F5F5', color: '#464646', fontSize: '14px', fontWeight: 600, letterSpacing: '3%', fontFamily: 'Inter', mr: '1%' }}>45 KM</Button>


                </Toolbar>
            </AppBar>
        </>
    );
};

export default Header;
