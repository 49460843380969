
import { Footer } from "./Footer";
import Header from "./Header";
import Locations from "./Locations";
import { JoinCard } from "./JoinCard";
export const Directory = () => {

    return (
        <div >
            <Header />
            <Locations />
            <JoinCard />
            <Footer />
        </div >
    );

};

