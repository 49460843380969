import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ReportPage } from "./components/ReportPage";
import EmptyPage from './components/404';
import { Directory } from './components/dental-directory/Directory';
import { SearchProvider } from './components/context/SearchContext';
function App() {
  return (
    <SearchProvider>
      <Router>
        <Routes>
          <Route exact path="/report/:id" element={<ReportPage />} />
          <Route exact path="/directory" element={<Directory />} />
          <Route path="*" element={<EmptyPage />} />
        </Routes>
      </Router>
    </SearchProvider>
  );
}

export default App;
