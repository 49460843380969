import React from 'react';
import { Card, CardContent, Typography, Button, styled, Grid } from '@mui/material';
import doctorImage from '../../static/dentist.png';

const RootContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '35rem',
    backgroundColor: '#fff',
});

const CustomCard = styled(Card)({
    backgroundColor: '#034641',
    width: '80%',
    position: 'relative',
    overflow: 'visible',
    borderRadius: '10px',
    padding: '3%'
});

const ContentContainer = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',

    color: '#fff',
    padding: '16px',
});

const ActionButton = styled(Button)({
    backgroundColor: '#F06E5A',
    color: '#fff',
    border: '2px solid #E9B3AB',
    marginTop: '16px',
    textTransform: 'none',
    borderRadius: '10px',
    padding: '5% 4%',
    '&:hover': {
        backgroundColor: '#F06E5A'
    }
});

const DoctorImage = styled('img')({
    position: 'absolute',
    right: '-20px',
    bottom: '0px',

});

export const JoinCard = () => {
    return (
        <RootContainer>
            <CustomCard>
                <ContentContainer>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography mb={3} style={{ fontFamily: 'Plus Jakarta Sans', fontWeight: '800', fontSize: '30px' }}>Join our Dental List</Typography>
                            <Typography style={{ fontFamily: 'Inter', fontSize: '16px' }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem culpa expedita saepe omnis repellendus hic ipsum iusto nobis, dignissimos ipsam.</Typography>
                            <ActionButton target="_blank" variant="contained" href="https://www.dentinnova.com">Join Our Directory Now →</ActionButton> </Grid>
                        <Grid item xs={12} md={6}>
                            <DoctorImage sx={{ width: { xs: '0px', md: '26.25rem' } }} src={doctorImage} alt="Doctor" />

                        </Grid>
                    </Grid>
                </ContentContainer>
            </CustomCard>
        </RootContainer>
    );
};

