import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Button from '@mui/material/Button';
import { Download } from '@mui/icons-material';
const DownloadPdfButton = ({ contentRef }) => {
    const handleDownloadPdf = async () => {
        const element = contentRef.current;

        
        try {
            html2canvas(element).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4', true);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const imgWidth = canvas.width;
                const imgHeight = canvas.height;
                const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
                const imgX = (pdfWidth - imgWidth * ratio) / 2;
                const imgY = 0;
                pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
                pdf.save('patient-report.pdf');
            });
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    return (
        <Button
            variant='contained'
            startIcon={<Download />}
            onClick={handleDownloadPdf}
        >
            Download PDF
        </Button>
    );
};

export default DownloadPdfButton;
