// MapComponent.jsx

import { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { ListItem, ListItemIcon, ListItemText, Avatar } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import './map-style.css';
import { Icon } from 'leaflet';
import navigationIcon from '../../static/navigation.png';
import { useMap } from 'react-leaflet';

const MapComponent = ({ selectedLocation, location }) => {
    const [center, setCenter] = useState({ lat: 48.8566, lng: 2.3522 });
    const [markerPosition, setMarkerPosition] = useState({ lat: 48.8566, lng: 2.3522 });
    const customIcon = new Icon({
        iconUrl: navigationIcon,
        iconSize: [38, 38]
    });
    useEffect(() => {
        if (selectedLocation) {
            const { lat, lng } = selectedLocation;
            setCenter({ lat, lng });
            setMarkerPosition({ lat, lng });
        }
    }, [selectedLocation]);

    const ZOOM_LEVEL = 13;

    function ChangeCenter({ position }) {
        const map = useMap();
        map.flyTo(position, ZOOM_LEVEL, { animate: true });
        return null;
    }


    return (
        <MapContainer center={center} zoom={ZOOM_LEVEL}>
            <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {selectedLocation &&
                <Marker icon={customIcon} position={markerPosition} >
                    <Popup>
                        <ListItem sx={{ pl: '0' }}>
                            <ListItemIcon sx={{ minWidth: 'unset', mr: '2%' }}>
                                <Avatar src={`${process.env.REACT_APP_BASE_URL}/api/clinic-images/${selectedLocation.iconName}`} />
                            </ListItemIcon>
                            <ListItemText primary={selectedLocation.clinicName} />
                        </ListItem>
                    </Popup>
                </Marker>
            }
            {location &&
                < Marker icon={customIcon} position={[location.lat, location.lng]}>
                    <Popup>
                        Your Location
                    </Popup>
                </Marker>
            }
            <ChangeCenter position={center} />
        </MapContainer>

    );
};

export default MapComponent;
